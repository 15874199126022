import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "app/utils/http/http";
import {
	CHECK_SDP_AVAILABILITIES,
	CLEAR_SMARTDP,
	FETCH_SDP_ACCOMMODATION_DESCRIPTION,
	FETCH_SDP_AVAILABLE_DEPARTURE_DATE,
	FETCH_SDP_FILTERS,
	FETCH_SDP_QUOTE,
	FETCH_SDP_QUOTE_FAILURE,
	RESET_SDP_SEARCH_QUERY_STRING,
	SAVE_SDP_SEARCH_QUERY_STRING,
	SAVE_SELECTED_SDP_PRODUCT,
	SHOW_MORE_SDP_PRODUCTS,
	UPDATE_ACTIVE_SORT,
} from "app/pages/SmartDP/smartDPActionTypes";
import { QUOTATION_CODE_STATUS } from "app/constants";

export const saveSDPProduct = accommodation => {
	return {
		type: SAVE_SELECTED_SDP_PRODUCT,
		accommodation,
	};
};

export const fetchSDPAvailableDepartureDate = uri => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");
	return {
		type: FETCH_SDP_AVAILABLE_DEPARTURE_DATE,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/${shop}/${uri}.json`),
	};
};

export const fetchSDPFilters = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_SDP_FILTERS,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/${shop}/sdp/filters.json`),
	};
};

// on simule une BAD_REQUEST dans le cas où on a pas de duration valide ou appeler /quote
// afin que l'on affiche un message pour inviter l'utilisateur à utiliser le form sdp pour relancer une nouvelle recherche
// et que l'état de la page ne soit plus en loading
// une duration peut être absente si on ne trouve pas de destination ni ville de depart à partir des params dans l'url
export const setSdpQuotePayloadError = () => {
	return {
		type: FETCH_SDP_QUOTE_FAILURE,
		error: {
			response: {
				data: {
					status: QUOTATION_CODE_STATUS.BAD_REQUEST,
				},
			},
		},
	};
};

export const fetchSdpQuote = quotationPayload => {
	// @see https://perfectstay.atlassian.net/browse/FUX-21
	// On a besoin de passer le token uniquement pour savoir si un operateur est connecté ou non.
	// On effet, le token contiendra l'email de l'operator et le service de quote nous renverra l'email de l'operator

	return {
		type: FETCH_SDP_QUOTE,
		promise: axios.post(
			`${env("QUOTE_API_URL")}/sdp/quote`,
			{
				...quotationPayload,
			},
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const fetchSdpQuoteWithCurrency = quotationPayload => {
	return {
		type: FETCH_SDP_QUOTE,
		promise: axios.patch(
			`${env("QUOTE_API_URL")}/sdp/quote/currency`,
			{ ...quotationPayload },
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const showMoreSDPProducts = () => {
	return {
		type: SHOW_MORE_SDP_PRODUCTS,
	};
};

export const saveSDPSearchQueryString = search => {
	return {
		type: SAVE_SDP_SEARCH_QUERY_STRING,
		search,
	};
};

export const resetSDPSearchQueryString = () => {
	return {
		type: RESET_SDP_SEARCH_QUERY_STRING,
	};
};

export const fetchSDPAccommodationDescription = ({ code, accommodationId }) => {
	return {
		type: FETCH_SDP_ACCOMMODATION_DESCRIPTION,
		promise: axios.post(`${env("QUOTE_API_URL")}/sdp/accommodationDescription`, {
			code,
			accommodationId,
		}),
	};
};

export const checkSDPAvailibilities = ({ code, accommodationId, boardCode, price, abTests }) => {
	return {
		type: CHECK_SDP_AVAILABILITIES,
		promise: axios.post(`${env("QUOTE_API_URL")}/sdp/accommodationQuote`, {
			code,
			accommodationId,
			boardCode,
			price,
			abTests,
		}),
	};
};

export const updateActiveSort = ({ activeSort }) => {
	return {
		type: UPDATE_ACTIVE_SORT,
		activeSort,
	};
};

export const clearSmartDP = () => {
	return {
		type: CLEAR_SMARTDP,
	};
};
